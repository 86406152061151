<template>
  <div class="vip-layout">
    <v-container>
      <section>
        <div class="content">
          <div class="title">
            <div class="d-inline">
                客戶分級設定
            </div>
            <v-btn
              depressed
              @click="toggleAddVipItemPopup(true)"
              class="flex-grow-1 btn02 d-inline float-right"
              color="#009775">
              新增分級
            </v-btn>
            <label for="vip-file-upload" class="custom-file-upload d-flex align-center justify-flex-start float-right mr-2 flex-grow-1">
              <input
                id="vip-file-upload"
                type="file"
                hidden
                @change="clickExcelImport"
              />
              <v-btn
                depressed
                class="btn02 d-inline"
                style="pointer-events: none;"
                :loading="excelImportBtnLoading">
                <div class="add_title">Excel匯入</div>
              </v-btn>
            </label>
            <v-btn
              depressed
              @click="excelImportSample"
              class="flex-grow-1 btn02"
              color="#009775">
              下載範例檔案
            </v-btn>
          </div>

          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
          <template v-else>
            <v-btn class="btn level_btn btn_graybg my-2" v-for="item in list" :key="item.id" @click="goDetail(item)">
              <div class="level_title">{{ item.name }}</div>
              <div class="level_icon">
                <i aria-hidden="true" class="v-icon notranslate fas fa-caret-right theme--light"></i>
              </div>
            </v-btn>

          </template>
        </div>
      </section>
    </v-container>
    <PrimaryModal
      :show="addVipItemPopup.switch"
      title="新增客戶分級"
      @close="toggleAddVipItemPopup(false)"
    >
      <v-card-text>
        <p>請輸入客戶分級名稱</p>
        <v-textarea
          class="d-flex justify-end"
          auto-grow
          variant="outlined"
          outlined
          rows="1"
          row-height="15"
          v-model="addVipItemPopup.name"
          color="#009775"
          maxlength="7"
        ></v-textarea>
      </v-card-text>
      <v-card-text>
        <div class="d-flex justify-center">
          <!--close-->
          <v-btn
            class="flex-grow-1 mr-4 btn01"
            outlined
            @click="toggleAddVipItemPopup(false)"
            color="#009775">
            取消
          </v-btn>

          <!--apply-->
          <v-btn
            depressed
            @click="confirmAddVipItemPopup"
            class="flex-grow-1 btn02"
            :loading="addVipItemPopup.loading"
            :dark="addVipItemPopup.name !== ''"
            :disabled="addVipItemPopup.name === ''"
            color="#009775">
            確認
          </v-btn>
        </div>
      </v-card-text>
    </PrimaryModal>
  </div>
</template>
<script>
import PrimaryModal from "components/modal/primaryModal.vue";
import { saveAs } from 'file-saver';

export default {
  components: {
    PrimaryModal,
  },
  data: () => ({
    loading: false,
    excelImportBtnLoading: false,
    list: [],
    addVipItemPopup: {
      switch: false,
      name: '',
      loading: false
    },
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
  },
  mounted () {
    this.$root.gaLogEvent('供應商_客戶分級頁')
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const result = await this.$api.collection.vipApi.read(this.providerId)
        this.list = result.map(v => Object.assign(v, {isSelected: false}))
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    toggleAddVipItemPopup (action) {
      this.addVipItemPopup.switch = action
    },
    async confirmAddVipItemPopup () {
      try {
        this.$root.gaLogEvent('供應商_點擊_建立客戶分級')
        this.addVipItemPopup.loading = true
        const { name } = this.addVipItemPopup
        await this.$api.collection.vipApi.add(this.providerId, { name })
        this.$snotify.success('新增成功')
        this.fetchData()
        this.toggleAddVipItemPopup(false)
      } catch (error) {
        console.error(error)
        this.$snotify.error('新增失敗')
      } finally {
        this.addVipItemPopup.loading = false
      }
    },
    clickExcelImport (file) {
      if (!file) return
      if (file.length === 0) return
      const fileOverSize = (size) => {
        const Number1MBSIZE = 100 * 1024 * 1024
        return size > Number1MBSIZE
      }
      const acceptType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      if(!acceptType.includes(file.target.files[0].type)){
        this.$snotify.warning('檔案類型不支援')
        return
      }
      if (fileOverSize(file.target.files[0].size)) {
        this.$snotify.warning('上傳檔案不得大於100MB')
        return
      }
      this.excelImport(file)
    },
    setLoading (status) {
      this.excelImportBtnLoading = status
    },
    async excelImport (file) {
      this.setLoading(true)
      try {
        let formData = new FormData()
        formData.append('file', file.target.files[0])
        await this.$api.collection.vipApi.excelImport(this.providerId, formData)
        await this.fetchData()
        this.$snotify.success('匯入成功')
      } catch (error) {
        console.error(error)
        this.$snotify.error('匯入失敗')
      } finally {
        this.setLoading(false)
      }
    },
    async excelImportSample () {
      const response = await this.$api.collection.vipApi.excelImportSample()
      saveAs(response.url, response.name)
    },
    goDetail (item) {
      this.$router.push(
        {
          name: "client-vipDetail",
          params: { id: item.id },
          query: {
            name: item.name,
            list: JSON.stringify(this.list)
          },
        });
    }
  }
}

</script>

<style lang="scss"> 
.vip-layout{
  .content {
    width: 100%;
    min-height: calc( 100vh - 174px);
  }

  .title {
    font-size: 24px;
    color: #000;
    margin: 30px 0;
  }

  .note_title {
    font-size: 16px;
    color: #122A47;
  }

  .btn {
    width: 100%;
    box-shadow: none !important;
  }

  .level_btn {
    width: 100%;
    min-height: 57px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .level_title {
      font-size: 16px;
      width: 100%;
      text-align: left;
    }
    
    .level_icon {
      min-width: 48px;
      min-height: 36px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .btn_graybg {
    background: #f6f8fa;
  }

  .btn_whitebg {
    border: 1px solid #F1F1F1;
    background: #fff !important;
  }


  .alert {
    width: 100%;
    min-height: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .alert_title {
      width: 100%;
      text-align: left;
    }

    .btn_red {
      background: #ED5550 !important;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 10px;
      min-width: 93px;
      min-height: 36px;
    }
  }

  .alert2 {
    border: 1px solid #F1F1F1;

    .alert2_icon {
      min-width: 48px;
      min-height: 36px;
      border-radius: 10px;
      background: #f6f8fa;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none !important;

      .theme--light.v-icon {
        font-size: 25px;
        color: #009775;
      }
    }
  }

  .btn01 {
    color: #009775 !important;
    background: #fff !important;
    border: 1px solid #009775 !important;

  }

  .btn02 {
    color: #fff !important;
    background: #009775 !important;
    border: 1px solid #009775 !important;
  }

  .btn03 {
    color: #fff !important;
    background:#ED5550 !important;
  }

  .v-text-field>.v-input__control>.v-input__slot:before,
  .v-text-field>.v-input__control>.v-input__slot:after {
    border: none;
  }

  .v-select__slot {
    position: relative;
    align-items: center;
    display: flex;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    border: 1px solid #000;
    padding: 0 10px;
  }

  .add_btn_content {
    width: 100%;

    .btn_add {
      width: 100%;
      border: 1px solid #009775;
      background: #fff !important;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 67px;

      .add_icon {
        .theme--light.v-icon {
          font-size: 25px;
          color: #009775;
        }
      }

      .add_title {
        font-size: 16px;
        color: #009775;
      }
    }

    .add_btn_box {
      width: 100%;
      border: 1px solid #009775;
      background: #fff;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

    }
  }
}

</style>